































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ShippingFeeRegionDialogProperties } from "./ShippingFeeRegionDialogView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import StateService from "@/services/StateService";

@Component({
    data: () => ({
        filter: {
            country: "",
            code: "",
            name: ""
        },
        pageNo: 1,
        pageCount: 1,
        recordCount: 20,
        recordCounts: [10, 20, 30, 40, 50],
        states: [],
        loading: false
    })
})
export default class ShippingFeeRegionStateView extends Vue {
    @Prop() private properties: ShippingFeeRegionDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private stateService = new StateService();

    public get headers() {
        return [
            {
                text: this.$t("text.country"),
                value: "country.name",
                filter: v => {
                    if (!this.$data.filter.country) return true;
                    return v.toLowerCase().includes(this.$data.filter.country.toLowerCase());
                }
            },
            {
                text: this.$t("text.code"),
                value: "code",
                filter: v => {
                    if (!this.$data.filter.code) return true;
                    return v.toLowerCase().includes(this.$data.filter.code.toLowerCase());
                }
            },
            {
                text: this.$t("text.name"),
                value: "name",
                filter: v => {
                    if (!this.$data.filter.name) return true;
                    return v.toLowerCase().includes(this.$data.filter.name.toLowerCase());
                }
            }
        ];
    }

    public get footerProps() {
        return {
            "items-per-page-options": this.$data.recordCounts
        };
    }

    public created() {
        this.load();

        this.properties.events.subscribe("load-states", this.load);
    }

    public destroyed() {
        this.properties.events.remove("load-states", this.load);
    }

    public async load() {
        this.$data.loading = true;

        try {
            const countries = this.properties.selectedCountries;
            if (countries.length !== 0) {
                var countryId = countries.map(x => x.id).join(",");
                const r = await this.stateService.multiple({ countryId, loadCountry: true });

                var states = r.data.states;
                states = states.sort((lhs, rhs) => {
                    var i = lhs.country.id - rhs.country.id;
                    if (i === 0) {
                        return lhs.code.localeCompare(rhs.code);
                    }
                    return i;
                });
                this.$data.states = states;
            } else {
                this.$data.states = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public selectionChanged() {
        var states = this.properties.selectedStates;
        var cities = this.properties.selectedCities;

        cities = cities.filter(x => {
            return states.filter(y => y.id === x.stateId).length > 0;
        });
        this.properties.selectedCities = cities;
    }
}
